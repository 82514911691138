<template>
  <div class="flex w-100 content-center justify-center pt-4">
    <div class="copyright">
      <p>
        © {{ getCurrentYear }}
        <a href="https://www.hva.nl/">Hogeschool van Amsterdam</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBottomVue',
  computed: {
    getCurrentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.container {
  border-top: 1px solid #ebebeb;
}
.copyright {
  font-size: 14px;
  font-weight: 400;
}
</style>
