const { axiosCreate } = require('./Api')
import store from '../state/store'

async function getCategories() {
  const result = await axiosCreate.get('shop/categories', {
    headers: { authorization: store.getters.token }
  })

  return result.data
}

async function getCategory({ categoryID = null } = {}) {
  let categoryIDList = []

  if (categoryID && categoryID.toString().includes(',')) {
    categoryIDList = categoryID.split(',')
  } else {
    categoryIDList.push(categoryID)
  }

  const result = await axiosCreate.get(
    `shop/categories/${categoryIDList.reverse()[0]}`,
    {
      params: { ContactID: store.getters.contactID },
      headers: { authorization: store.getters.token }
    }
  )
  return result.data
}

async function getDefaultCategory() {
  const result = await axiosCreate.get(`shop/categories/default`, {
    headers: { authorization: store.getters.token }
  })
  return result.data
}

module.exports = { getCategories, getCategory, getDefaultCategory }
