<template>
  <div class="flex justify-center pt-3">
    <div class="pl-3 pr-3"><router-link to="/">Home</router-link></div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="hover:text-primary-light text-gray"
        href="https://hva.nl/home/disclaimers/disclaimer"
        rel="noreferrer"
        >Disclaimer</a
      >
    </div>

    <div class="pl-3 pr-3">
      <a
        target="_blank"
        href="https://hva.nl/home/disclaimers/privacy"
        rel="noreferrer"
        >Privacy</a
      >
    </div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        href="mailto:uitleen-bph@hva.nl"
        rel="noreferrer"
        >Contact</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterTop'
}
</script>
