const state = {
  activeWarehouse: 'BPH',
  activeReturnWarehouse: 'BPH',
  accounts: [],
  account: {},
  acceptedCookies: false,
  anonymousToken: null,
  bearerToken: null,
  cartCache: {},
  cartCount: 0,
  cart: [],
  contactID: null,
  customerID: null,

  DateTimeBusinessStart: '',
  DateTimeBusinessEnd: '',
  DateTimeExpectedStart: '',
  DateTimeExpectedEnd: '',
  lastVisitDate: new Date(),
  user: {},

  mutationProblems: [],

  reservation: {},

  searchTerm: null,
  gridListing: true,
  reservations: null,
  subUsers: [],
  toastMessage: null,
  role: '',
  itemStore: {},

  loadingDateWarehouse: false,
  warehouses: [
    {
      value: '',
      text: 'Kies een locatie',
      disabled: true
    },
    { value: 'BPH', text: 'BPH' }
  ],
  temporaryWarehouses: []
}

export default state
